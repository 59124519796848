/** When your routing table is too long, you can split it into small modules */

import Layout from '../../views/layout/TheLayout.vue';
import Vue from "vue";

const blankRouter = {
  path: '/blank22',
  component: Layout,
  redirect: 'noredirect',
  name: 'Permission',
  meta: {
    title: 'Бланки44',
    icon: 'mdi-file-document-outline',
  },
  children: [
    {
      path: 'doc-blanks-income',
      component: () => Vue.component('Country', import('../../views/components/Directory/ListTable')),
      props: {
        model: 'DocBlanksIncome',
        dataRequest: 'DocBlanksIncome',
        component: 'ListTableFile'
      },
      meta: { title: 'Приход бланков', roles: ['admin'], noCache: true },
    },
    {
      path: 'blank-reason',
      component: () => Vue.component('Country', import('../../views/components/Directory/ListTable')),
      props: {
        model: 'BlankReason'
      },
      meta: { title: 'Причины выбытия бланков', roles: ['editor'], noCache: true },
    },
    {
      path: 'doc-blanks-main-reject',
      component: () => Vue.component('Country', import('../../views/components/Directory/ListTable')),
      props: {
        model: 'DocBlanksMainReject',
        component: 'ListTableBlank'
      },
      meta: { title: 'Выбытие основных бланков', roles: ['editor'], noCache: true },
    },
    {
      path: 'doc-blanks-add-reject',
      component: () => Vue.component('Country', import('../../views/components/Directory/ListTable')),
      props: {
        model: 'DocBlanksAddReject',
        component: 'ListTableBlank'
      },
      meta: { title: 'Выбытие дополнительных бланков', roles: ['visitor'], noCache: true },
    },
    {
      path: 'visitor',
      component: () => Vue.component('Country', import('../../views/components/Directory/ListTable')),
      props: {
        model: 'DocBlanksIncome'
      },
      meta: { title: 'Отчет об использовании бланков', roles: ['visitor'], noCache: true },
    },
  ],
};

export default blankRouter;
