import router, { asyncRoutes, constantRoutes } from '../../router';
import http from "../../api/http-common";
import Layout from "../../views/layout/TheLayout";
import Vue from "vue";
import Swal from "sweetalert2";
// import store from "../index";
// import {apiDocument} from "../../api/api-document";

/**
 * Through meta.role determines whether the current user rights match
 * @param roles
 * @param route
 */
const hasPermission = (roles, route) => {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta.roles.includes(role));
  }
  return true;
};

/**
 * Recursively filter asynchronous routing tables to return routing tables
 * that meet user role permissions
 * @param roles
 * @param routes asyncRoutes
 */
export const filterAsyncRoutes = (roles, routes) => {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(roles, tmp.children);
      }
      res.push(tmp);
    }
  });
  return res;
};

const permission = {
  state: {
    routes: [],
    addRoutes: [],
  },

  getters: {
    permissionRoutes: (state) => state.routes,
    addRoutes: (state) => state.addRoutes,
  },

  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes;
      state.routes = constantRoutes.concat(routes);
    },
  },
  actions: {
    GenerateRoutes: async ({ commit }, { roles }) => {
      try {
        console.groupCollapsed(`[vuex.permission] GenerateRoutes [${roles}]`);
        let accessedRoutes;
        // let apiDocumentValue = new apiDocument();
        let menu = await http.get('/utils/MainMenu');
        let newMenu = [];
        menu.data.forEach((value) => {
          let buildRout = {
            ...value, ...{
              name: 'Permission',
              component: Layout,
              redirect: 'noredirect',
            }
          };
          if (buildRout.children.length <= 0) {
            Swal.fire({
              title: 'Ошибка построения меню: отсутствует значение для [buildRout.children]',
              html: `<textarea id='text' style="min-height: 332px; width: 100%; font-size: 11px;">${JSON.stringify(buildRout, null, 4)}</textarea>`,
              icon:'error'
            })
          } else {
            buildRout.children = buildRout.children.map(value => {
              let newItem = value;
              if (newItem.children == null)
                delete newItem.children;
              if (newItem.props.model == null)
                newItem.props.model = newItem.path.split("/")[1];
              newItem.props.dataRequest = newItem.path.split("/")[1];
              // newItem.
              return {
                ...newItem,
                ...{
                  component: () => Vue.component('Country', import('../../views/components/Directory/ListTable')),
                }
              };
            });
            if (buildRout.children != null)
              newMenu.push(buildRout);
          }
        });
        // console.log(asyncRoutes);
        console.log(newMenu)
        // console.log([...newMenu, ...asyncRoutes]);
        if (roles.includes('admin')) {
          // accessedRoutes = asyncRoutes;
          accessedRoutes = [...newMenu, ...asyncRoutes];
        } else {
          // accessedRoutes = filterAsyncRoutes(roles, asyncRoutes);
          accessedRoutes = filterAsyncRoutes(roles, [...newMenu, ...asyncRoutes]);
        }
        commit('SET_ROUTES', accessedRoutes);
        // Apply selected allowed routes
        router.addRoutes(accessedRoutes);
        console.log('[vuex.permission] accessedRoutes ', constantRoutes);
        console.groupEnd();
      } catch (err) {
        console.warn('[vuex.permission] GenerateRoutes', err);
      }
    },
  },
};

export default permission;
