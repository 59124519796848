/**
 * Login by email and password
 *
 * @param {String} email user email
 * @param {String} password user password
 */
import {userAdmin, userEditor} from "./mock";
import http from "./http-common";
import Swal from "sweetalert2";

export const loginByEmail = async (email, password) => {
  console.log(`[loginByEmail] email ${email}`);
  let user = {};
  try {
    if (userEditor.email === email && userEditor.password === password) {
      user = userEditor;
      return { user };
    } else {
      try {
        let response = await http.post('session', {
          login: email,
          password: password,
        })
        user = userAdmin;
        user.name = response.data.profileName;
        user.token = response.data.token;
        return { user };
      }
      catch (err) {
        if (err.response.status === 401) {
          Swal.fire(
              'Ошибка авторизации',
              'Неверный логин или пароль',
              'error'
          )
        }
        console.warn(`[PizdecRequestHttp] ${err}`);
      }
    }
    if (!user || !user.token) {
      throw new Error('User is not found');
    }
  } catch (err) {
    console.warn(`[loginByEmail] ${err}`);
  }
};

/**
 * Get user information by token
 *
 * @param {String} token user token
 */
export const getUserInfo = async (token) => {
  console.log(`[getUserInfo] token ${token}`);
  let user = {};
  try {
    if (!token) {
      throw new Error('Invalid token');
    }

    if (token === userAdmin.token) {
      user = userAdmin;
    } else {
      user = userEditor;
    }
  } catch (err) {
    console.warn(`[getUserInfo] ${err}`);
  }
  return { user };
};
