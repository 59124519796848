import Vue from 'vue'
import Vuex from 'vuex'
import syncStorage from "./plugins/syncStorage";
import settings from './modules/settings';
import permission from "./modules/permission";
import user from './modules/user';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  plugins: [
    syncStorage({}),
  ],
  actions: {
  },
  modules: {
    settings,
    permission,
    user,
  }
})
